(function ($) {

    $(document).ready(function () {
        var minWidth = 1100

        /**
         * Click on submit button
         */
        document.querySelector('#searchsubmit').addEventListener('click', function (e) {
            if (window.innerWidth < minWidth) {
                e.preventDefault()
                var searchIsEmpty = hideShowSearch($('.header-search'));
                if(!searchIsEmpty){
                    $("#searchform").submit();
                }
            }
        })

        document.addEventListener('scroll', function () {
            if (window.innerWidth < minWidth) {
                var y = window.scrollY;

                // show/hide sticky menu
                if (y > 10) {
                    // Remove mobile search on scroll
                    hide($('.header-search'));

                } else {

                }
            }
        })
    });

})(jQuery);

function hideShowSearch(elem) {
    if ($(elem).find('input').val() == '') {
        if ($(elem).attr('data-open') === 'open') {
            hide(elem)
        } else {
            show(elem)
        }
        return true;
    }else{
        return false;
    }
}

function hide(elem) {
    $(elem).attr('data-open', 'close');
    $('header .header-logo').removeClass('header-logo--open');
    $('#header-search-input').removeAttr('style');
    $('.header-search').removeClass('header-search--open');
    $('.search_div').removeClass('search_div--open');
}

function show(elem) {
    $(elem).attr('data-open', 'open');
    $('header .header-logo').addClass('header-logo--open');
    $('#header-search-input').show(150);
    $('.header-search').addClass('header-search--open');
    $('.search_div').addClass('search_div--open');
}